<template>
    <section class="home" role="main">
      <!-- Header -->
      <header id="header" class="header">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6">
                      <div class="text-container">
                          <h1 class="h1-large"> {{ $t('landing-h1') }} </h1>
                          <h2 class='sub-title'> {{ $t('landing-h2') }} </h2>
                          <div class='cta-links py-4'>
                            <router-link to='/search' tabindex="0"><span class="btn-solid-lg"> {{ $t('landing-cta') }} </span></router-link>
                            <!-- <span class="btn-solid-lg"> {{ $t('landing-cta-coming-soon') }} </span> -->
                          </div>
                          <!-- <p class='text-muted'> {{ $t('landing-gpt-tagline') }} </p> -->
                      </div> <!-- end of text-container -->
                  </div> <!-- end of col -->
                  <div class="col-lg-6">
                      <div class="image-container">
                          <img v-if="mockup==='ien'" id='device-mockup' class="img-fluid" src="../assets/img/iPhone-English.png" alt="Chachi on iPhone" @click='mockupClick()'>
                          <img v-if="mockup==='aen'" id='device-mockup' class="img-fluid" src="../assets/img/Android-English.png" alt="Chachi on Android" @click='mockupClick()'>
                          <img v-if="mockup==='ihi'" id='device-mockup' class="img-fluid" src="../assets/img/iPhone-Hindi.png" alt="आइफ़ोन पर चाची" @click='mockupClick()'>
                          <img v-if="mockup==='ahi'" id='device-mockup' class="img-fluid" src="../assets/img/Android-Hindi.png" alt="ऐंड्रॉड फ़ोन पर चाची" @click='mockupClick()'>
                      </div> <!-- end of image-container -->
                  </div> <!-- end of col -->
              </div> <!-- end of row -->
          </div> <!-- end of container -->
      </header> <!-- end of header -->
      <!-- end of header -->

      <!-- Article -->
      <article class='col-10 mx-auto'>
        <div class=''>
          <h2>{{$t('landing-how-to-section-title')}}</h2>
          <h3 class='text-start py-2'>{{$t('landing-how-to-section-subtitle1')}}</h3>
          <div class="accordion accordion-flush text-start" id="accordionFlush1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  {{$t('how-to-accordion-1-title')}}
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlush1">
                <div class="accordion-body">
                  {{$t('how-to-accordion-1-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  {{$t('how-to-accordion-2-title')}}
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlush1">
                <div class="accordion-body">
                  {{$t('how-to-accordion-2-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                  {{$t('how-to-accordion-3-title')}}
                </button>
              </h2>
              <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlush1">
                <div class="accordion-body">
                  {{$t('how-to-accordion-3-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                  {{$t('how-to-accordion-4-title')}}
                </button>
              </h2>
              <div id="flush-collapse9" class="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlush1">
                <div class="accordion-body">
                  {{$t('how-to-accordion-4-body')}}
                </div>
              </div>
            </div>
          </div>
          <h3 class='text-start py-2'>{{$t('landing-how-to-section-subtitle2')}}</h3>
          <div class="accordion accordion-flush text-start" id="accordionFlush2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                  {{$t('how-to-accordion-5-title')}}
                </button>
              </h2>
              <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlush2">
                <div class="accordion-body">
                  {{$t('how-to-accordion-5-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading7">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                  {{$t('how-to-accordion-6-title')}}
                </button>
              </h2>
              <div id="flush-collapse7" class="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlush2">
                <div class="accordion-body">
                  {{$t('how-to-accordion-6-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                  {{$t('how-to-accordion-7-title')}}
                </button>
              </h2>
              <div id="flush-collapse8" class="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlush2">
                <div class="accordion-body">
                  {{$t('how-to-accordion-7-body')}}
                </div>
              </div>
            </div>
          </div>
          <h3 class='text-start py-2'>{{$t('landing-how-to-section-subtitle3')}}</h3>
          <div class="accordion accordion-flush text-start" id="accordionFlush3">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                  {{$t('how-to-accordion-8-title')}}
                </button>
              </h2>
              <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlush3">
                <div class="accordion-body">
                  {{$t('how-to-accordion-8-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                  {{$t('how-to-accordion-9-title')}}
                </button>
              </h2>
              <div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlush3">
                <div class="accordion-body">
                  {{$t('how-to-accordion-9-body')}}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                  {{$t('how-to-accordion-10-title')}}
                </button>
              </h2>
              <div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlush3">
                <div class="accordion-body">
                  {{$t('how-to-accordion-10-body')}}
                </div>
              </div>
            </div>
          </div>
          <div class='mt-3'>
            <router-link to='/how-to' class='sub-link' tabindex="0">{{$t('how-to-learn-more-button')}}</router-link>
          </div>
          <!-- <div class='testimonials'>
            <p id='large-comma'></p>
            <p id='testimonial'></p>
          </div> -->
        </div>
      </article>
    </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Landing',
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Chachi',
  },
  components: {
  },
  data() {
    return {
      mockup: ''
    }
  },
  methods: {
    mockupClick() {
      console.log('click')
      this.$router.push('/search')
    },
    fillTestimonial() {
      var enItems = ["This is an amazing resource for parents and educators.",
                "I can't stop thinking about these questions!",
                "Some of these are extraordinary. Wow.",
                "My kids love Chachi! They are so excited about learning now.",
                "This is truly a 21st century way of learning.",
                "I like that I end up learning with my students.",
                "Finally an app that prioritises learning over usage!",
                "Chachi has been a lifesaver. It has really elevated my conversations with my students.",
                "Chachi has made ME so curious! I love it!"]
      var pick = Math.floor(Math.random() * enItems.length)
      document.getElementById('testimonial').textContent = enItems[pick]
    },
    setMockup() {
      var lang = this.$store.state.lang
      var pick = ['a', 'i'][Math.floor(Math.random() * 2)]
      this.mockup = pick + lang
    }
  },
  created() {
    this.setMockup()
  },
  mounted() {
    // this.fillTestimonial()
  }
}
</script>

<style scoped>
.h1-large {
  font-size: 2rem;
  line-height: 2.5rem;
}
.header {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}

.header .text-container {
  margin-bottom: 3rem;
}

.header .h1-large {
  margin-bottom: 1.25rem;
}

.header .p-large {
  margin-bottom: 2rem;
}

.header .btn-solid-lg {
  margin-right: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.25rem;
}

@media (min-width: 768px) {
  .header {
    padding-top: 4rem;
  }
  .h1-large {
    font-size: 2.25rem;
  }
}

@media (min-width: 992px) {
  .header {
    text-align: left;
  }

  .header .text-container {
    margin-top: 5rem;
  }

  .header .btn-solid-lg {
    margin-right: 0.5rem;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .h1-large {
    font-size: 3rem;
    line-height: 3.25rem;
  }
  .header {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  .header .text-container {
    margin-top: 6rem;
    margin-right: 3rem;
  }

  .header .image-container {
    text-align: right;
  }
}
.btn-solid-lg {
  display: inline-block;
  padding: 1.625rem 2.75rem 1.625rem 2.75rem;
  border: 1px solid #594cda;
  border-radius: 32px;
  background-color: #594cda;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}
.btn-solid-lg:hover {
  border: 1px solid #594cda;
  background-color: transparent;
  color: #594cda; /* needs to stay here because of the color property of a tag */
  text-decoration: none;
}
.sub-title {
  font-weight: normal;
}
.sub-link {
  color: var(--bs-gray-dark);
  font-size: 1.5rem;
}
#device-mockup {
  cursor: pointer;
}
</style>